<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Nation groups") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <euro-input v-model="searchFilter" class="min-w-300px mb-0 mr-0" :debounce="300"
          :placeholder="$t('Search')"></euro-input>

        <b-button v-b-tooltip :title="$t('Create new nation group')" variant="light-primary" class="font-weight-bolder"
          @click="createGroup('nation-group-modal')">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Home/Earth.svg" />
          </span>
          {{ $t("Create group") }}
        </b-button>
      </div>

    </div>
    <div class="card-body detail">
      <datatable ref="datatableRef" hover :table-props="tableProps" :total="total" :options.sync="tableOptions">
        <template #[`cell.id`]="{ row }">
          <i class="pointer" :class="[row.detailsShowing ? icons.caretUp : icons.caretDown]"
            @click="row.toggleDetails"></i>
        </template>
        <template #[`cell.name`]="{ item }">
          <div class="d-flex align-items-center">
            <div class="ml-3">
              <div class="font-weight-bolder" :style="{ wordBreak: 'break-word' }">{{ item?.name }}</div>
            </div>
          </div>
        </template>
        <template #[`cell.description`]="{ item }">
          <div class="d-flex align-items-center">
            <div class="ml-3">
              <div class="description-group" :style="{ wordBreak: 'break-word' }">{{ item?.description }}</div>
            </div>
          </div>
        </template>
        <template #[`cell.actions`]="{ item }">
          <div class="d-flex">
            <b-button v-b-tooltip :title="$t('Edit')" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
              @click="editGroup('nation-group-modal', item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
            </b-button>
            <b-button v-b-tooltip :title="$t('Delete')" class="btn btn-icon btn-light btn-hover-primary btn-sm"
              @click="removeGroup(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/General/Trash.svg" />
              </span>
            </b-button>
          </div>
        </template>
        <template #row-details="row">
          <b-table :items="row.row.item.nationingroup_set" :fields="nestedFields">
            <template #cell(country)="{ item }">
              <div v-if="item.country">
                <CountryFlag :country-iso="item.country"></CountryFlag>
              </div>
            </template>
            <template #cell(iso)="{ item }">
              {{ item.country }}
            </template>

            <template #cell(adhesion_date)="{ item }">
              <div class="d-flex align-items-center">
                {{ item.adhesion_date }}
              </div>
            </template>
          </b-table>
        </template>
      </datatable>
      <NationGroupModal :existing-form="editForm" :countries="getCountries" @refresh="refresh">
      </NationGroupModal>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import icons from "@/core/config/icons";
import NationsGroupsService from "@/core/services/nation/nations-groups.service";
import CountryFlag from "@/view/components/CountryFlag.vue";
import Swal from "sweetalert2";
import Datatable from "@/view/components/tables/Datatable.vue";
import { mapGetters } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import NationGroupModal from "@/view/components/modals/nations/NationGroupModal.vue"

export default {
  components: {
    Datatable,
    CountryFlag,
    NationGroupModal,
  },

  data() {
    return {
      icons,
      isLoading: false,
      itemFilter: "ALL",
      editForm: {},
      countries: null,
      fields: [
        { key: "id", label: "", sortable: false },
        { key: "name", label: this.$t("Name"), sortable: true },
        { key: "description", label: this.$t("Description"), sortable: false, tdClass: "description-group" },
        { key: "actions", label: this.$t("Actions") },
      ],
      nestedFields: [
        { key: "country", label: `${this.$t("Country")}`, class: ["table-align", "font-weight-bolder"] },
        { key: "iso", label: `${this.$t("Iso")}`, class: ["table-align", "font-weight-bolder"] },
        { key: "adhesion_date", label: `${this.$t("Adhesion date")}`, class: ["table-align", "font-weight-bolder"] },
      ],
      items: [],
      total: 0,
      perPageOptions: [5, 15, 25, 50],
      searchFilter: "",
      tableOptions: {
        "no-local-sorting": true,
      },
    };
  },

  computed: {
    ...mapGetters("constants", ["getCountries", "countriesMap"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter,
        responsive: true,
        "no-sort-reset": true,
        "tbody-tr-class": this.trClass,
      };
    },
  },

  watch: {
    itemFilter() {
      this.refresh();
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Groups Nation") },
      { title: this.$t("Manage") },
    ]);
    this.countries = this.getCountries;


  },

  methods: {

    itemProvider(ctx, callback) {
      NationsGroupsService.getGroupsPaginated({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "created",
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        fields: 'name,description,nationingroup_set'
      }).then((res) => {
        this.total = res.count;
        callback(res.results);
      }).catch((err) => {
        backendErrorSwal(err, this.$t("failed to load items"));
        callback([]);
      });

      return null;
    },
    refresh() {
      this.$refs.datatableRef.refresh();
    },
    async createGroup(id) {
      this.editForm = {};
      this.$bvModal.show(id);
    },
    async editGroup(id, node) {
      this.editForm = { ...node };
      this.$bvModal.show(id);
    },
    removeGroup(item) {
      Swal.fire({
        title: this.$t("Delete"),
        text: `${this.$t("Are you sure you want to delete the nation group ")} ${item.name}?`,
        icon: "warning",
        confirmButtonClass: "btn btn-suuccess",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t("No, do NOT delete"),
        cancelButtonClass: "btn",
      }).then(res => {
        if (res.isConfirmed) {
          this.isLoading = true;
          NationsGroupsService.delete(item.id)
            .then(() => {
              this.isLoading = false;
              this.refresh();
              Swal.fire(this.$t("Success"), this.$t("Nation group successfully deleted!"), "success");
            }).catch(err => {
              console.error(err);
              backendErrorSwal(err, err?.response?.data?.error);
            })
        }
      });
    }

  }
};
</script>

<style scoped>
::v-deep .description-group {
  max-width: 60ch;
}
</style>



