var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Nation groups")) + " ")])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-input', {
    staticClass: "min-w-300px mb-0 mr-0",
    attrs: {
      "debounce": 300,
      "placeholder": _vm.$t('Search')
    },
    model: {
      value: _vm.searchFilter,
      callback: function callback($$v) {
        _vm.searchFilter = $$v;
      },
      expression: "searchFilter"
    }
  }), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "font-weight-bolder",
    attrs: {
      "title": _vm.$t('Create new nation group'),
      "variant": "light-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.createGroup('nation-group-modal');
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Home/Earth.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Create group")) + " ")])], 1)]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "hover": "",
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.id",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('i', {
          staticClass: "pointer",
          class: [row.detailsShowing ? _vm.icons.caretUp : _vm.icons.caretDown],
          on: {
            "click": row.toggleDetails
          }
        })];
      }
    }, {
      key: "cell.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "font-weight-bolder",
          style: {
            wordBreak: 'break-word'
          }
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))])])])];
      }
    }, {
      key: "cell.description",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "description-group",
          style: {
            wordBreak: 'break-word'
          }
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.description))])])])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('Edit')
          },
          on: {
            "click": function click($event) {
              return _vm.editGroup('nation-group-modal', item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "title": _vm.$t('Delete')
          },
          on: {
            "click": function click($event) {
              return _vm.removeGroup(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-table', {
          attrs: {
            "items": row.row.item.nationingroup_set,
            "fields": _vm.nestedFields
          },
          scopedSlots: _vm._u([{
            key: "cell(country)",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [item.country ? _c('div', [_c('CountryFlag', {
                attrs: {
                  "country-iso": item.country
                }
              })], 1) : _vm._e()];
            }
          }, {
            key: "cell(iso)",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_vm._v(" " + _vm._s(item.country) + " ")];
            }
          }, {
            key: "cell(adhesion_date)",
            fn: function fn(_ref7) {
              var item = _ref7.item;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_vm._v(" " + _vm._s(item.adhesion_date) + " ")])];
            }
          }], null, true)
        })];
      }
    }], null, true)
  }), _c('NationGroupModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "countries": _vm.getCountries
    },
    on: {
      "refresh": _vm.refresh
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }